import './App.css';
import React, { useState } from 'react';
import logo from './imagens/logo.png';

function App() {

  document.title = "Get Thumbnail";

  const [lingua, setLingua] = useState("en");

  function obterImg() {

    var videoURL = document.getElementById('videoURL');
    var boxImg = document.getElementById('boxImg');

    var videoId = videoURL.value;
    if (videoId.startsWith("http://")) {
      let len = videoId.length - 1;
      let tmp = videoId.substr(7, len);
      videoId = tmp;
    }
    else if (videoId.startsWith("https://")) {
      let len = videoId.length - 1;
      let tmp = videoId.substr(8, len);
      videoId = tmp;
    }
    else {
      videoId = "";
    };
    if (videoId.startsWith("youtu.be/")) {
      let leng = videoId.length - 1;
      let temp = videoId.substr(9, leng);
      videoId = temp;
    }
    else if (videoId.startsWith("youtube.com/watch?v=")) {
      let leng = videoId.length - 1;
      let temp = videoId.substr(20, leng);
      videoId = temp;
    }
    else if (videoId.startsWith("m.youtube.com/watch?v=")) {
      let leng = videoId.length - 1;
      let temp = videoId.substr(22, leng);
      videoId = temp;
    }
    else if (videoId.startsWith("www.youtube.com/watch?v=")) {
      let leng = videoId.length - 1;
      let temp = videoId.substr(24, leng);
      videoId = temp;
    }
    else {
      videoId = false;
    }

    if (videoId || videoId != "") {
      var urlLink = "https://img.youtube.com/vi/" + videoId + "/maxresdefault.jpg";

      if (lingua == "en") {
        boxImg.innerHTML = `
          <img src=`+ urlLink + `>
          <a href="`+ urlLink + `" target="_blank">Open image</a>
        `;
      } else {
        boxImg.innerHTML = `
          <img src=`+ urlLink + `>
          <a href="`+ urlLink + `" target="_blank">Abrir imagem</a>
        `;
      }

    } else {
      if (lingua == "en") {
        boxImg.innerHTML = `
          <div class="boxErro">
              <h2>Invalid URL!</h2>
          </div>
      `;
      } else {
        boxImg.innerHTML = `
            <div class="boxErro">
                <h2>URL inválida!</h2>
            </div>
        `;
      }
    }

  }

  function btnLingua() {
    var btnLingua = document.getElementById('btnLingua');
    var textTitle = document.getElementById('textTitle');
    var textSubTitle = document.getElementById('textSubTitle');
    var btnObter = document.getElementById('btnObter');

    if (lingua == "en") {
      setLingua("pt");

      btnLingua.innerHTML = "Translate to English";
      textTitle.innerHTML = "Obtenha a Thumbnail de qualquer vídeo do Youtube!";
      textSubTitle.innerHTML = "Basta colar a URL do vídeo no campo abaixo";
      btnObter.innerHTML = "Obter Thumbnail!";
    }

    if (lingua == "pt") {
      setLingua("en");

      btnLingua.innerHTML = "Traduzir para o Português";
      textTitle.innerHTML = "Get the Thumbnail of any Youtube video!";
      textSubTitle.innerHTML = "Just paste the video URL in the field below";
      btnObter.innerHTML = "Get Thumbnail!";
    }
  }

  return (
    <div className="App">

      <header>
        <a target="_blank" href="https://viniciushnf.com/" >
          <img src={logo} />
        </a>
        <div className="btnLingua">
          <button onClick={() => { btnLingua() }} id="btnLingua">Traduzir para o Português</button>
        </div>
      </header>

      <section>
        <div class="title">
          <h1 id="textTitle">Get the Thumbnail of any Youtube video!</h1>
          <p id="textSubTitle">Just paste the video URL in the field below</p>
        </div>

        <div class="in">
          <input onKeyUp={() => obterImg()} type="text" id="videoURL" placeholder="https://www.youtube.com/watch?v=XXXXXXX" />
          <h5 onClick={() => obterImg()} id="btnObter">Get Thumbnail!</h5>
        </div>

        <div id="boxImg" class="box">

        </div>
      </section>
    </div>
  );
}

export default App;
